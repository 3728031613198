import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchDelete(ctx, id){
            return new Promise((resolve, reject) => {
              axios
                .delete('/facility/v1/delete/'+id)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
          },
        fetchData(ctx, queryParams){

            var data = JSON.stringify(queryParams);
            // console.log('queryParams',data);
            return new Promise((resolve, reject) => {
                
                axios
                .post('facility/v1/lists', data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        
        },
       
    }
}